const axios = require('axios');

export default {
  getSiteAsTemplate(page = 0) {
    const instWithCred = axios.create({
      headers: {
        'X-Organization-ID': 134,
        'X-Organization-Custom-Auth': '0ANjGH0282CaRMINTuRToKnmDLRBr-R2x6FvkgPZ0lc=',
      },
    });
    return instWithCred.get(
      `https://assistant.impactfactors.net/api/core-settings/widget/1.0/?sorting[position]=asc&filter[type_id]=5&page=${(Math.ceil(page / 10) + 1)}&onpage=10&expand=series&include=contentStructure`,
    );
  },
};
